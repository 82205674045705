 /*font-family: 'Spartan', sans-serif;*/

body{ font-family: 'Open Sans', sans-serif; background-color: #000;}

h1, h2, h3, h4, h5, h6{ font-family: 'Chewy', cursive; } 
.countdown{font-family: 'Chewy', cursive !important;}
.countdown .countdown-container { width: 100px; }
.countdown .countdown-container .countdown-heading{ color: #fff !important; letter-spacing: 1px; font-size: 14px; }

.contract-address{font-size: 23px; }
section h2{font-size: 90px; margin-bottom: 0px; color: #fff; text-align: center; margin-bottom: 0px;}

/*-- Mint pages css start here --*/
.mint-section .mint-img{}
.mint-section .mint-img img{ width: 100%; }
.mint-section{ padding: 50px 0; }
.mint-section .top-text h4{ font-size: 50px; text-align: center; font-weight: bold;  letter-spacing: 1px;}
.mint-section .mint-text{ font-size: 40px !important;}
.mint-section .mint-group input{ font-family: 'Chewy', cursive !important;   width: 90px;
    height: 60px;
    border: none;
    color: #e62e2d;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    background-color: #fff;
    margin: 0 15px;
    border-radius: 5px;  padding: 0px 0px 0 15px;
    padding: 0;
  }
.mint-section .mint-group  span{cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    border: 3px solid #fff;
    padding: 12px 0;
}
.mint-section .mint-group span i{ font-size: 30px; }
.mint-section .mint-group span:hover{ background-color: #f7e353; color: #e62e2d; border-color: #e62e2d;}
.mint-section .mint-group{ margin-top: 25px; }
.mint-section .btn-mint{ font-family: 'Chewy', cursive; border: 3px solid #f7e353; color: #e62e2d; font-size: 35px; font-weight: bold; text-transform: uppercase; margin-top: 30px; width: 200px; height: 80px; border-radius: 15px; letter-spacing: 1px;}
.mint-section .btn-mint:hover{background-color: #f7e353; border-color:#e62e2d ; }
.mint-form{ text-align: center; }
.mint-section .mint-text{ color: #fff; }
.mint-section .mint-form p{ font-family: 'Chewy', cursive; color: #fff; font-size: 25px; letter-spacing: 1px;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
/*-- Mint pages css end here --*/

.page-404-section{ min-height:150px; text-align: center; margin-top:100px; }

.container-xl, .container-lg, .container-md, .container-sm, .container{ max-width: 1300px; }

.top-header{ width: 100%; background-color: #f7e353;}   

.top-header .navbar{ padding:8px 0 10px 0; } 

.top-header li a{ font-family: 'Chewy', cursive; letter-spacing: 0.5px; font-size: 25px; color: #000;  font-weight: bold; padding-left: 25px !important;  padding-right: 25px !important;}

.top-header li a:hover{color: #e62e2d;   }  

.top-header li a.active{color: #e62e2d;   }

.nav-link:hover, .nav-link:focus{ color: #e62e2d }

.top-header li:last-child a{ padding-right: 0px !important; }

.top-header .right-box .btn-mint{ background-color: white; display: inline-block; padding: 2px 30px 3px 30px; color: red; text-transform: uppercase; text-decoration: none; border-radius: 50px; font-size: 35px; font-weight: bold; line-height: initial; }

.top-header .right-box .btn-mint:hover{ background-color: #ffffffd6; }

.top-header .right-social-box a{display: inline-block; color: #000; font-size: 25px; margin: 0 10px;}

.top-header .right-social-box{ margin-right: 20px; }

.top-header .navbar-brand{  font-family: 'Chewy', cursive; letter-spacing: 1px; }

.top-header .navbar-brand{ color: #000 !important; font-size: 22px; font-weight: bold;}

.banner-section{ background-color: #000; padding: 40px 0;}

.launch-date{ text-align: center; font-family: 'Open Sans', sans-serif; margin: 20px 0 0 0; color: #fff; font-weight: 600; font-size: 26px;}


.banner-section .banner-text{ margin-top: 20px; }

.banner-section .banner-text h2{line-height: 1em; color: #fff; font-weight: bold; font-size: 80px; letter-spacing: 1px; margin-bottom: 10px;}

.red-text{color: #e62e2d;}
  
.yellow-text{color: #f7e353;}

.banner-section .banner-text p{ line-height: 1.2em;  margin-bottom: 0px; color: #f6f6f6; font-size: 30px; font-weight: 300; }

.circel-box{ position: relative; }

.banner-section .banner-img{ position: relative; text-align: center; }

.banner-section .banner-img .circel-box2 .circel-img{ width: 380px; right: initial; left: 265px;  top: -270px;}
.banner-section .circel-box2{ top: 0; height: 0px;}
.banner-section .circel-box2 .gif-img{ width: 310px !important; top: -255px;  right: 13px !important; left: auto;}
.divider{ padding: 0 0 0 0; display: none;}
.divider hr{height: 2px; background-color: #e62e2d; opacity: 1;}
.banner-section .banner-img img{ width: 330px; }

.banner-section .banner-img .circel-img{position: relative; z-index: 2;}

.banner-section .gif-img{ position: absolute; top: 60px; width: 260px !important; left: 70px; z-index: 1;}

.discord-section{ background-color: #000; padding: 50px 0; }


.discord-section .discord-text{  }

.discord-section .discord-text h2{ margin-bottom: 0;}

.discord-section .discord-text p{ font-size: 30px; font-family: 'Spartan', sans-serif; color: #fff; margin: 20px 0;}

.discord-section .discord-text a{display: inline-block; color: #fff; text-decoration: none; background-color: #e74035; padding: 20px 50px; font-size: 35px; font-weight: 600; border-radius: 100px; margin-top: 15px; }

.discord-section .discord-text a:hover{     background-color: #e22c20; }

.discord-section .discord-box{ background-image: url('./assets/images/discord-img.png'); background-size: cover; background-repeat: no-repeat; background-position: top center; padding: 155px 0;}

.discord-section .discord-box p{ font-size: 25px; color: #fff; font-weight: 500; margin-bottom: 5px;}

.discord-section .full-box p{ font-weight: 700; margin-bottom: 0; margin-top: 10px;}

.discord-section .design-box{text-align: center;}
.discord-section .design-box table{ width: 80%; border-color: #211d1d; margin: 0 auto; border-top: none; border-bottom: none;}
.discord-section .design-box table td{ padding: 7px; width: 50%; vertical-align: middle; border-color: #211d1d;}
.discord-section .design-box table td .img-box{ width: 70px; text-align: center; margin-right: 15px; display: inline-block; vertical-align: middle; }
.discord-section .design-box table td .img-box img{ width: 70px; } 
.discord-section .design-box table td .text-box{ width: 43%; text-align: center; vertical-align: middle; font-size: 16px; text-transform: uppercase; font-weight: bold; color: red; display: inline-block;}
.discord-section .design-box table td:nth-child(odd){ border-left: none;  text-align: left;}
.discord-section .design-box table td:nth-child(even){ border-right: none; text-align: right;}
.discord-section .design-box table td:nth-child(even) .img-box{ margin-right: 0px; margin-left: 15px; }
.discord-section .design-box table td.last-td{ padding-bottom: 0px; text-align: center; border-right: none; border-bottom: none; border-bottom: 1px solid transparent !important;}
.discord-section .design-box table tr:first-child td{ border-top: 1px solid transparent !important; }
.discord-section .design-box table td.last-td .text-box{ width: auto !important; }
.feature-section{  padding: 60px 0;  background-color: #000;}

.feature-section .icon-box i{ font-size: 35px; color: #fff;  text-align: center; }

.feature-section .feature-list{ margin-bottom: 60px; }

.feature-section .feature-detail{ padding-left: 30px; }

.feature-section .feature-detail h4{color: #fff; font-size: 33px; font-weight: bold; text-align: center; letter-spacing: 1px; margin-bottom: 0; }

.feature-section .feature-detail p{ color: #fff; }

.feature-img .img-box{position: relative;  }

.feature-img .img-box img{ width: 100%; }

.feature-img .img-box img.circel-img{ width: 65%; margin: 0 auto; }

.feature-img .img-box .design-img{position: absolute; top: 40px; left: 170px; width: 200px; }

.feature-section h2{ margin-bottom: 0px; }

.feature-section .icon-box img{ width: 100%; }

.trait-section{ padding: 60px;}

.data-box{background-color: #f5baff; padding: 40px; border-radius: 30px; box-shadow: 0px 0px 30px 0px #0000001c; }

.data-box table{ margin-bottom: 0px; }

.data-box table td{font-size: 20px; font-weight: bold; width: 9%; border: none; }

.experience { padding: 60px 0; }

.team-section{ padding:60px 0 60px 0; }

.team-section h2{  text-align: center;}

.animation-slide{ position: relative; margin-top: 60px;}

.animation-slide img{ width: 100%; }

.btn-box{background: linear-gradient(0deg,#ba68c8 40%,rgba(180,120,218,0));  height: 160px; width: 100%; z-index: 5;  position: absolute;  bottom: 0;}

.btn-box a{ margin-top: 100px; }

.team-box{ text-align: center; margin-top: 50px;}

.team-box h4{font-family: 'Spartan', sans-serif; margin-bottom: 10px; font-size: 28px; color: #fff;}

.team-box img{ width: 200px; }

.team-box p{ color: #ff1616; font-size: 28px; margin-bottom: 0; margin-top: 30px; font-weight: 400;}

.social-icon a{ display: inline-block; width: 70px; margin-right: 20px; text-align: center;}

.social-icon a img{ width: 50px; }

.social-icon a svg{width: 60px; }

.social-icon a .discord-icon{ width: 60px; }

.footer-section{ padding: 60px 0 20px 0;} 

.social-icons a i{ font-size: 30px; color: #fff; }

.social-icons a{ display: inline-block; margin: 0 25px; }

.social-icons{ margin: 30px 0; }

.footer-section p{ margin-bottom: 0px; font-size: 30px; color: #fff; }

.footer-section a{ text-decoration: none; color: #5cb7fe;}
.footer-section a i{ font-size: 45px; color: #fff; }
.footer-section a:hover{ color: #2b91e0; }  
.footer-section a:hover i { color: #2b91e0; }    

.as-seen-section{ padding:50px 0 60px ; }
.as-seen-section img{ width: 80%; }

.footer-section .footer-logo{ font-size: 25px; font-weight: bold; color: #fff; text-decoration: none;}

.footer-section .footer-logo {  }

.qts-img{ width: 90px; }

.faq-section{ padding: 60px 0 60px 0; }

.faq-section h2.heading{ text-align: center; margin-bottom: 30px;}

.faq-section .accordion-item .accordion-button { display: block; background-color: #f7e353; text-align: center; border: none; color: #000; font-size: 30px; letter-spacing: 1px; }

.faq-section .accordion-item .accordion-button:focus{ outline: none; box-shadow: none;}

.faq-section .accordion-item .accordion-body{ text-align: center;font-family: 'Open Sans', sans-serif; font-weight: normal; background-color: #000; color: #fff; font-size: 20px;}

.faq-section .accordion-item{ border: none; margin-bottom: 10px; }

.faq-section .accordion-button::after{ background-image: url('./assets/images/arrow-drop.svg'); position: absolute; right: 20px; top: 37%;}

#roadmap { padding: 60px 0; }

.roadmap-section h2 { margin-bottom: 100px;  color: #fff;}

#roadmap .timeline {margin: 0 auto; max-width: 1500px; position: relative;}

#roadmap .timeline:after { background-color: #fff; bottom: 0; content: ""; left: 50%; margin-left: -2px; position: absolute; top: 0; width: 4px;}

#roadmap .timeline-card { background-color: inherit;  margin-top: -50px; position: relative; width: 50%;}

#roadmap .timeline-card:after { background-color: #fff;content: ""; height: 4px; position: absolute; right: 0; top: 30px; width: 25px; z-index: 1;}

#roadmap .timeline-card .timeline-content { display: -webkit-box; display: -ms-flexbox; display: flex;}

#roadmap .timeline-card .timeline-content img { border-radius: 5px; height: 72px; margin-right: 10px; width: 72px;}

#roadmap .timeline-card.left {left: 0; padding: 0 40px 0 20px;}

#roadmap .timeline-card.right { left: 50%; padding: 0 20px 0 40px;}

#roadmap .timeline-card.right .content {text-align: left;}

#roadmap .timeline-card.right:after {left: 0;} 

#roadmap .content { border-radius: 5px; padding: 20px 30px; position: relative; text-align: right; margin-bottom: 15px;}

#roadmap .content h3{ color: #f7e353; margin-bottom: 15px; font-size: 35px; letter-spacing: 1px; }

#roadmap .content p{ color: #fff; font-size: 28px; margin-bottom: 0px;}


#gallery {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 600px;
    position: relative;
  }
  #gallery div.bg {
    -webkit-animation-duration: 20000s;
    animation-duration: 20000s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: url(./assets/images/bored-babes-banner.jpg) 0 0/cover repeat #fff;
    height: 600px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-size: contain;
  }

@keyframes MOVE-BG{0%{background-position-x:0}to{background-position-x:-1000000px}}



@media screen and (max-width: 1199px) {
  .discord-section .design-box table{ width: 100%;}
    #gallery div.bg { background-size: cover; }

  .banner-section .banner-text{     margin-top: 60px; }

  .banner-section .circel-box2 .gif-img{ right: 50px !important; }

  .banner-section .banner-img img{ width: 300px; }

  .banner-section .gif-img{ width: 160px !important; }

  .banner-section .banner-img .circel-box2 .circel-img{width: 250px !important; top: -200px; left: 210px; }

    .banner-section .circel-box2 .gif-img { width: 150px !important; top: -160px;}

    .banner-section .circel-box2{ height: 30px; }

.top-header li a{ font-size: 20px; padding-left: 10px !important; padding-right: 10px !important }

.top-header .navbar-brand{ font-size: 20px; }

.top-header .right-box .btn-mint{ font-size: 30px; }

.top-header .right-social-box a{ margin: 0 2px; }

.container-lg, .container-md, .container-sm, .container {max-width: 960px;}

.banner-section .banner-text h2{ font-size: 60px;}

.banner-section .banner-text p{ font-size: 20px; }

.launch-date{    font-size: 25px;}

section h2{ font-size: 50px; }

.discord-section .discord-text p{ font-size: 20px; }

.discord-section .discord-text a { font-size: 20px;padding: 15px 40px; }

.discord-section .discord-box p{ font-size: 18px; }

.discord-section .discord-box{     padding: 110px 0; }

.feature-section .feature-detail h4{ font-size: 25px; }

.feature-section .feature-detail{ padding-left: 20px; } 

.feature-section .feature-list{ margin-bottom: 30px; }

.feature-img .img-box .design-img{top: 30px; left: 113px; width: 140px; }

#roadmap .content h3{ font-size: 30px; }

#roadmap .content p{ font-size: 20px; }

.qts-img{ width: 70px; }

.team-box h4{ font-size: 20px; }

.team-box p{ font-size: 20px; }

.social-icon a{ width: 50px; }

.footer-section p{ font-size: 20px; }

.social-icon a img{ width: 40px; }

.social-icon a .discord-icon{ width: 50px; }

.social-icon a svg{ width: 50px; }

}



@media screen and (max-width: 991px) {
.footer-section a i{font-size: 25px; }
.discord-section .design-box table td .img-box{ width: 40px; }
.discord-section .design-box table td .img-box{ margin-right: 5px; }
.discord-section .design-box table td .img-box img{ width: 40px;}
.feature-img .img-box .design-img{ top: 23px; left: 85px; width: 100px; }
.discord-section .design-box table td .text-box{ font-size: 12px; }


.banner-section { padding: 20px 0; }
.banner-section .banner-text{margin-top: 40px;}
.container-lg, .container-md, .container-sm, .container {max-width: 720px;}

.banner-section .circel-box2 .gif-img{ right: -7px !important; }

.banner-section .banner-img img{ width: 250px; }

.banner-section .gif-img{ left: 40px; top: 40px;}

.banner-section .banner-img .circel-box2 .circel-img{ width: 230px !important; top: -180px; left: 180px;} 

.banner-section .circel-box2 .gif-img{right: -33px !important;top: -150px; }





.banner-section .banner-text h2{ font-size: 40px; margin-bottom: 0px;}

.banner-section .banner-text p{ font-size: 18px; }

.launch-date{ font-size: 20px; margin: 20px 0 0 0; }

section h2{ font-size: 40px; }

.discord-section .discord-box p{ font-size: 14px; margin-bottom: 0px; }

.discord-section .discord-box{ padding: 90px 0; }

.discord-section .discord-text p{ font-size: 18px; }

.feature-section .feature-detail h4{ font-size: 18px; }

.feature-section .feature-detail{ padding-left: 10px; }

#roadmap .content h3{ font-size: 25px; }
.as-seen-section{ padding:50px 0 60px ; }
.as-seen-section img{ width: 80%; }
#roadmap .content p{ font-size: 18px; }

.faq-section .accordion-item .accordion-button{ font-size: 25px; }

.faq-section .accordion-item .accordion-body{ font-size: 18px; }

.team-box h4{ font-size: 16px; }

.team-box p{ font-size: 16px; }

.social-icon a { width: 30px; }

.footer-section p{ font-size: 16px; }

.social-icon a img { width: 30px; }

.social-icon a .discord-icon{ width: 40px; }

.social-icon a svg{width: 40px; }



}

@media screen and (max-width: 767px) {  
  .as-seen-section {padding: 0px 0 30px;}
  .as-seen-section img{ width: 50%; }
  .discord-section .design-box{ margin-top: 40px; }
  .discord-section .design-box table{ width: 400px }
#gallery{height:400px;}
#gallery div.bg{ height: 400px; }
  .countdown .countdown-container { width: 60px; }
.countdown .countdown-container .countdown-heading{ color: #fff !important; letter-spacing: 1px; font-size: 12px; }
.countdown .countdown-container .countdown-value{ font-size: 20px; }
    .discord-section .discord-box p{ font-size: 13px; } 
  .discord-section .discord-box{padding: 50px 0;
    width: 320px;
    margin: 0 auto; } 
    .discord-section .full-box p{ margin-top: 0px; }
  .banner-section{ padding-bottom: 30px; }
  .discord-section { padding: 30px 0; }
  .feature-section{ padding: 30px 0 30px 0px; }
  #roadmap{ padding: 30px 0; }
  .faq-section{ padding: 30px 0 30px 0; }
  .team-section{ padding: 30px 0 30px 0; }
  .animation-slide{ margin-top: 30px; }
  .team-box p{ margin-bottom: 0px; }

  .top-header .right-social-box a {margin: 0 5px 10px 5px;}

  .banner-section .banner-img{ width: 100%; margin: 15px auto 0;}

  .banner-section .circel-box2 .gif-img{ right: 0 !important; }

  .banner-section .banner-img img{ width: 300px }

  .banner-section .gif-img{ left: 24px; top: 13px; }

    .banner-section .banner-img .circel-box2 .circel-img{ width: 180px !important; top: -130px; left: 125px;}

    .banner-section .circel-box2 .gif-img{ width: 140px !important; top: -115px; right: 22px !important;}

    .banner-section .banner-text{ margin-top: 20px; }


.discord-section .discord-text{ margin-top: 0px; }

.feature-section h2 {margin-bottom: 30px;} 

.feature-img .img-box img.circel-img{ width: 200px; }

.feature-img .img-box .design-img{ top: 32px; left: 0; right: 0;  margin: 0 auto; }

.feature-img{margin-bottom: 40px;  }

.feature-section .icon-box img{ width: 100%; }

.qts-img { width: 50px; }

.footer-section{ text-align: center; }

.footer-section .mt-5{ margin-top: 0px !important; }

.social-icon { margin-bottom: 15px; }

.team-box p{ margin-bottom: 10px; }

}

@media screen and (max-width: 600px) {

#roadmap h2 { margin-bottom: 50px;}

#roadmap .timeline:after { left: 30px;}

#roadmap .timeline-card { margin-top: 0; width: 100%; }

#roadmap .timeline-card:before { border: solid transparent;border-right: solid #fff; border-width: 10px 10px 10px 0; left: 60px;}

#roadmap .timeline-card.left { padding: 0 20px 0 40px; }

#roadmap .timeline-card.left .content { text-align: left; }

#roadmap .timeline-card.left:after,

#roadmap .timeline-card.right:after { left: 30px; }

#roadmap .timeline-card.right { left: 0;}

}
@media screen and (max-width: 479px) { 
.discord-section .design-box table{ width: 100%; } 
.discord-section .design-box table td{ padding: 5px 0; }
.discord-section .design-box table td{ position: relative; }
.discord-section .design-box table td .img-box{ position: absolute; top: 5px; left: 0; right: 0;text-align: center; width: 100%; }
.discord-section .design-box table td:nth-child(even) .img-box{ margin-left: 0px; }
.discord-section .design-box table td .text-box { font-size: 14px; width: 100%; margin-top: 45px;}
}
@media screen and (max-width: 320px) {
.discord-section .discord-box{width: 293px;; }
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./assets/webfonts/fa-brands-400.eot"); 
  src: url("./assets/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("./assets/webfonts/fa-brands-400.woff2") format("woff2"), url("./assets/webfonts/fa-brands-400.woff") format("woff"), url("./assets/webfonts/fa-brands-400.ttf") format("truetype"), url("./assets/webfonts/fa-brands-400.svg#fontawesome") format("svg"); 
  
  }

.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./assets/webfonts/fa-regular-400.eot");
  src: url("./assets/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("./assets/webfonts/fa-regular-400.woff2") format("woff2"), url("./assets/webfonts/fa-regular-400.woff") format("woff"), url("./assets/webfonts/fa-regular-400.ttf") format("truetype"), url("./assets/webfonts/fa-regular-400.svg#fontawesome") format("svg"); 
  }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("./assets/webfonts/fa-solid-900.eot");
  src: url("./assets/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("./assets/webfonts/fa-solid-900.woff2") format("woff2"), url("./assets/webfonts/fa-solid-900.woff") format("woff"), url("./assets/webfonts/fa-solid-900.ttf") format("truetype"), url("./assets/webfonts/fa-solid-900.svg#fontawesome") format("svg"); 
 }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }
